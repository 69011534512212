<template>
    <surround-loader :is-loading="isLoading">
        <div class="d-flex">
            <h5 class="flex-fill" v-if="title != null">{{ title }}</h5>
            <div class="flex-wrap position-relative" v-if="dateFilter != null">
                <date-range-picker
                        class="d-block"
                        @update="loadData"
                        @input=""
                        control-container-class="form-control-sm reportrange-text"
                        ref="picker"

                        :auto-apply="true"
                        opens="left"
                        :ranges="ranges"
                        v-model="dateRange"
                        :dateRange="dateRange"
                        :locale-data="localeData"
                        date-util="moment"
                ></date-range-picker>
                <b-form-select v-if="dateStep != null" @input="loadData" class="flex-wrap mt-1" size="sm" v-model="step" :options="stepOptions"></b-form-select>
            </div>
        </div>
        <div class="p-3" v-if="type === 'pie' ">
            <api-pie-chart :options="pieOptions" :chart-data="data"></api-pie-chart>
        </div>

        <div class="p-3" v-if="type === 'line'">
            <api-line-chart v-if="!isLoading" :options="lineOptions"
                            :chart-data="data"></api-line-chart>
        </div>


        <div class="text-center" v-if="type === 'count'">
            <vue-count-up
                    class="h1"
                    :options="{
                                useEasing : true,
                                useGrouping : true,
                                prefix : isSterling != null ? '£' : '',
                                separator : isSterling != null ? ',' : ''
                                }"
                    :delay="0"
                    :endVal="this.numberData"
            >
            </vue-count-up>
        </div>

    </surround-loader>
</template>

<script>
    import ApiPieChart from "./ApiPieChart";
    import SurroundLoader from "../../SurroundLoader";
    import {saveResource} from "../../../modules/api/methods";
    import VueCountUp from "vue-countup-v2/src/countup";
    import ApiLineChart from "./ApiLineChart";
    import moment from "moment";
    import DateRangePicker from "vue2-daterange-picker/src/components/DateRangePicker";

    export default {
        name: "ApiChart",
        components: {DateRangePicker, ApiLineChart, VueCountUp, SurroundLoader, ApiPieChart},
        props: {
            title: null,
            endpoint: null,
            dateFilter: null,
            dateStep: null,
            type: null,
            isSterling: null,

        },
        data() {
            return {
                step : "week",
                stepOptions: [
                    { value: "day", text: 'Plot each day' },
                    { value: "week", text: 'Plot each week' },
                    { value: "month", text: 'Plot each month' },
                ],
                ranges : {
                    'Last week': [moment().subtract(1, 'weeks').toDate(), moment().toDate()],
                    'Last 1 month': [moment().subtract(1, 'months').toDate(), moment().toDate()],
                    'Last 3 months': [moment().subtract(3, 'months').toDate(), moment().toDate()],
                    'Last year': [moment().subtract(12, 'months').toDate(), moment().toDate()],
                },
                dateRange: {
                    startDate: moment().subtract(3, 'months').toDate(),
                    endDate: moment().toDate()
                },
                localeData: {
                    direction: 'ltr',
                    format: 'DD/MM/YYYY',
                    separator: ' - ',
                    applyLabel: 'Apply',
                    cancelLabel: 'Cancel',
                    weekLabel: 'W',
                    customRangeLabel: 'Custom Range',
                    daysOfWeek: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
                    monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                    firstDay: 0
                },
                isLoading: false,
                data: {},
                numberData: 0,
                lineOptions: {
                    responsive: true,
                    maintainAspectRatio: false,
                    aspectRatio: 2,
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true
                            }
                        }]
                    }
                },
                pieOptions: {
                    responsive: true,
                    onClick:this.handle
                }
            }
        },
        created() {
            this.loadData();
        },
        methods: {
            handle (point, event) {
                const item = event[0];
                if(item !== undefined && this.data['datasets'] !== undefined) {
                    console.log(item);
                    if (this.data['datasets'][item._datasetIndex]['onClick'] !== undefined) {
                        let route = this.data['datasets'][item._datasetIndex]['onClick'][item._index];
                        console.log(route);
                        this.$router.push(route);
                    }
                }
            },
            onReady: function (instance, CountUp) {
                const that = this;
                instance.update(that.endVal + 100);
            },
            loadData() {
                this.isLoading = true;
                var postData = {};

                if(this.dateFilter != null) {
                    postData['start_date'] = moment(this.dateRange.startDate).format("YYYY-MM-DD");
                    postData['end_date'] = moment(this.dateRange.endDate).format("YYYY-MM-DD");
                    postData['step'] = this.step;
                }
                saveResource(this.endpoint, postData).then((resp) => {
                    console.log("CAHRT DATE", resp);
                    this.data = resp.data.success;
                    if(this.type === "count") {
                        let data = resp.data.success;
                        this.numberData = parseFloat(data);
                    }
                }).catch((err) => {
                    console.log("CHART EROR", err);
                }).finally(() => {
                    this.isLoading = false;
                })
            }
        }
    }
</script>

<style scoped>

</style>
