<template>
    <div class="p-2">
        <div class="d-flex align-items-center mb-2">
            <b-breadcrumb class="mb-0 flex-fill" :items="breadcrumbs"></b-breadcrumb>
        </div>
        <div class="row row-deck px-3">
            <div class="col-12 col-lg-6 col-xl-4 mt-4 mt-lg-0">
                <div class="card">
                    <div class="card-body">
                        <api-chart title="Workspace/Non workspace" type="pie" :endpoint="endpoint.isWorkspace"></api-chart>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-6 col-xl-4 mt-4 mt-lg-0">
                <div class="card">
                    <div class="card-body">
                        <api-chart title="Signup drop off" type="pie" :endpoint="endpoint.signupDropOff"></api-chart>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-xl-4 d-flex flex-column mt-4 mt-xl-0">
                <div class="card flex-fill d-flex flex-column align-content-center justify-content-center">
                    <div class="p-3">
                        <api-chart type="count"  :endpoint="endpoint.analyticsLaunchCount"></api-chart>
                        <div class="text-muted text-center">
                            Organisations launched
                        </div>
                    </div>
                    <div class="p-3">
                        <api-chart type="count" :endpoint="endpoint.applicationsCount"></api-chart>
                        <div class="text-muted text-center">
                            Applications started
                        </div>
                    </div>

                    <div class="p-3">
                        <api-chart is-sterling="true" type="count" :endpoint="endpoint.revenueTotal"></api-chart>
                        <div class="text-muted text-center">
                            Total revenue
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12 mt-4 mb-4">
                <div class="card">
                    <div class="card-body mb-4" style="height: 500px;">
                        <api-chart date-step date-filter title="Sign ups with revenue" type="line" :endpoint="endpoint.revenue"></api-chart>
                    </div>
                </div>
            </div>

        </div>

    </div>
</template>

<script>
    import PaginatedResultLoader from "../../../../components/PaginatedResultLoader";
    import FiltersLoader from "../../../../components/FiltersLoader";
    import ApiChart from "../../../../components/analytics/graphs/ApiChart";
    import {
        analyticsApplicationsCount,
        analyticsIsWorkspace,
        analyticsLaunchCount, analyticsRevenue, analyticsRevenueTotal,
        analyticsSignupDropOff
    } from "../../../../modules/api/endpoints";

    export default {
        name: "analytics",
        components: {ApiChart, FiltersLoader, PaginatedResultLoader},
        data: function () {
            return {
                endpoint: {
                    isWorkspace: analyticsIsWorkspace,
                    signupDropOff: analyticsSignupDropOff,
                    analyticsLaunchCount: analyticsLaunchCount,
                    applicationsCount: analyticsApplicationsCount,
                    revenueTotal: analyticsRevenueTotal,
                    revenue: analyticsRevenue,
                },
                breadcrumbs: [
                    {
                        text: 'Dashboard',
                        to: {'name': 'dashboard'}
                    },
                    {
                        text: 'Analytics',
                        active: true
                    }
                ]
            }
        },
        methods: {}

    }
</script>
